:root {
  --primary: #116af9;
  --secondary: #709fba;
  --primary-hover: #2274f9;
  --primary-dark: #217069;
  --rgba-primary-1: rgba(91, 207, 197, 0.1);
  --rgba-primary-2: rgba(91, 207, 197, 0.2);
  --rgba-primary-3: rgba(91, 207, 197, 0.3);
  --rgba-primary-4: rgba(91, 207, 197, 0.4);
  --rgba-primary-5: rgba(91, 207, 197, 0.5);
  --rgba-primary-6: rgba(91, 207, 197, 0.6);
  --rgba-primary-7: rgba(91, 207, 197, 0.7);
  --rgba-primary-8: rgba(91, 207, 197, 0.8);
  --rgba-primary-9: rgba(91, 207, 197, 0.9);
  --font-family-base: Roboto, sans-serif;
  --font-family-title: Roboto, sans-serif;
  --title: #000; 
}


/*
0 - 600: Phone
600 - 900: Tablet portrait
900 - 1200: Tablet landscape
1200 - 1800: Normal styles
1800+ : Big Desktop
1em = 16px
The smaller device rules always should write below the bigger device rules
Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
*/

:root {
  --bs-blue: #5e72e4;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #e83e8c;
  --bs-red: #EE3232;
  --bs-orange: #ff9900;
  --bs-yellow: #FFFA6F;
  --bs-green: #297F00;
  --bs-teal: #20c997;
  --bs-cyan: #3065D0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #5bcfc5;
  --bs-secondary: #709fba;
  --bs-success: #68e365;
  --bs-info: #b48dd3;
  --bs-warning: #ffa755;
  --bs-danger: #f72b50;
  --bs-light: #c8c8c8;
  --bs-dark: #6e6e6e;
  --bs-primary-rgb: rgb(91, 207, 197);
  --bs-secondary-rgb: rgb(112, 159, 186);
  --bs-success-rgb: rgb(104, 227, 101);
  --bs-info-rgb: rgb(180, 141, 211);
  --bs-warning-rgb: rgb(255, 167, 85)
  --bs-danger-rgb: rgb(247, 43, 80);
  --bs-light-rgb: rgb(200, 200, 200);
  --bs-dark-rgb: rgb(110, 110, 110);
  --bs-white-rgb: rgb(255, 255, 255);
  --bs-black-rgb: rgb(0, 0, 0);
  --bs-body-color-rgb: rgb(150, 155, 160);
  --bs-body-bg-rgb: rgb(246, 246, 246);
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: Roboto, sans-serif;
  --bs-body-font-size: 0.875rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #969ba0;
  --bs-body-bg: #f6f6f6; }
  
  ::selection{
  background-color: var(--primary);
  color: var(--bs-white);
  }
  /* body{
    font-family: var(--bs-font-sans-serif);
  } */
  a:hover{
    color: black;
  }

.ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--primary-color) !important; /* Change the background color to green when checked */
    border:none !important
  }
  .ant-select-selector{
    border-radius: 0px !important;
  }
  .ant-checkbox-inner::after {
    border-color: white; /* Change the tick mark color to white */
  }
  .ant-select .ant-select-selector:hover{
    border-color: var(--primary) !important;
  }
  .ant-select-status-error .ant-select-selector:hover{
    border-color: #ff7875 !important;
  }
  .operatorSelect .ant-select-selector{
    height: 50px !important;
  }
  /* .operatorSelect .ant-select-dropdown {
    top: initial !important;
    bottom: 100% !important;
  } */
/* ALL SIDEBAR CLASSES TO CHANGE style*/
.sidebar aside.ant-layout-sider.ant-layout-sider-dark {
  background: #ffffff !important;
  border-radius: 15px;
  margin: 15px;
}
.sidebar .ant-menu{
  background-color: #ffffff;
  color: var(--textgraycolor);
  padding-bottom: 50px;
}
/* .sidebar .ant-layout {
  width: 100% !important;
} */
.sidebar .ant-menu-submenu-title{
  color: #000 !important;
}

.sidebar .ant-menu .ant-menu-item-selected{
  background-color: var(--primary) !important;
  /* color: var(--bs-white) !important; */
}
 .sidebar .ant-layout-header{
  padding-inline:0;
}
.sidebar .ant-menu-item:hover{
  background-color: var(--primary) !important; /* when hovering over on an item */
}

.sidebar li.ant-menu-item:active{
  background-color: var(--bs-white) !important;
}
.sidebar .ant-menu .ant-menu-item-selected:active{
  background-color: var(--primary) !important;
}

.sidebar .ant-menu  .ant-menu-submenu-selected .ant-menu-submenu-title{
color: var(--primary) !important;
}
.sidebar .ant-menu-submenu  .ant-menu-inline{
  background: var(--bs-white) !important;
  border-radius: 10px !important;
  /* margin:0px 15px 0px 15px !important; */
} 
 .ant-menu-submenu-popup .ant-menu .ant-menu-item {
  display: flex;
  justify-content: flex-start !important;
  align-items: center !important;
  background-color: var(--bs-white);
  color: #000;
}
.ant-menu-submenu-popup .ant-menu .ant-menu-submenu .ant-menu-submenu-title .ant-menu-title-content  p,.ant-menu-submenu-arrow{
  display: flex;
  justify-content: flex-start !important;
  align-items: center !important;
  background-color: var(--bs-white);
  color: #000 !important;
}
.ant-layout-sider-collapsed{
  width: 50px !important;
  min-width: 50px !important;
}
.ant-menu-submenu-popup .ant-menu .ant-menu-item-selected{
  background-color: var(--primary);

}
.ant-menu .ant-menu-sub{
  background-color: var(--bs-white) !important;
}
/* .ant-menu .ant-menu-sub li{
  padding-left: 21px !important;
} */
.ant-menu-submenu-popup .ant-menu .ant-menu-item:hover{
  background-color: var(--primary) !important;
}
.Nested_report .ant-menu-submenu-title{
  padding-left: 0px !important;
}
.Nested_report  .ant-menu .ant-menu-sub .ant-menu-inline{
  margin: 0px !important;
}
  /* RADIO BUTTON CSS */
input[type="radio"]:focus{
  --tw-ring-color:"white !important";
}
input[type="number"]:focus{
  --tw-ring-color:"white !important";
}
input[type="checkbox"]:focus{
  --tw-ring-color:"white !important";
}
input[type="text"]:focus{
  --tw-ring-color:"white !important";
}
input[type="password"]:focus{
  --tw-ring-color:"white !important";
}
input[type="search"]:focus{
  --tw-ring-color:"white !important";
}
input[type="checkbox"]{
  color: var(--primary);
}
input:focus{
  --tw-ring-color:"white"
}
/* input[type="text"]{
  border-radius: 7px !important;
} */
.otpinput:focus{
  --tw-ring-color:"white !important";
}
/* Customize the color of selected Radio buttons */
.radio-group .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-inner {
  background-color: var(--primary);
  border-color: transparent;
}
.radio-group .ant-radio-wrapper.ant-radio-wrapper-checked::after {
  background-color: white;
}

.radio-group .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-inner::after {
  background-color: white;
}
.radio-group .ant-radio-wrapper:hover .ant-radio-inner{
  border-color: var(--bs-gray-300) !important;
}
/* TABBAR  */
.ant-tabs-nav{
  margin: 0px !important;
}
.ant-tabs-nav::before{
 border: none !important; 
}
.ant-tabs-tab-btn{
  color: var(--bs-body-color) !important;
}
.ant-tabs-tab.ant-tabs-tab-active{
  color: #ffffff;
}
.ant-tabs .ant-tabs-ink-bar{
  background-color: var(--primary) !important;
}
.ant-tabs .ant-tabs-tabpane{
  background-color: white !important;
}
.required:after {
  content: " *";
  color: red;
}
/* CUSTOM SCROLLBAR */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #c8ced4;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background:  var(--primary);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* custom-table.css */
.custom-table thead.ant-table-thead tr th {
  background-color: var(--bs-white);
  color: var(--bs-gray);
  padding: 14px 20px;
  font-size: 14px;
  text-align: start !important;
  /* Set your desired background color */
  /* Add any other styles you want for the table header */
}

/* CUSTOM TABLE CSS TO CHANGE STYLE */
.custom-table th.ant-table-cell .ant-table-column-has-sorters {
  background-color: transparent !important;
  text-align: center !important;
}
.custom-table th.ant-table-cell {
  text-align: center;
}
.custom-table .ant-table-tbody > tr > td {
  padding: 10px 8px;
  width: 200px;
  text-align: start;
}

.custom-table .ant-table-tbody > tr {
  height: 2px;
  line-height: 24px;
}

/* ANTD DROPDOWN HEIGHT */
.ant-dropdown {
  height: 200px;
  overflow: auto;
}
.ant-spin-nested-loading {
  width: 100% !important;
}
/* .cardBg{
  background-image: url("./Assets//benificiaryCardPattern.png");
  background-color: var(--bs-blue);
  background-repeat: no-repeat;
} */
/* SPINNER CLASSES */
.ant-spin-dot .ant-spin-dot-item{
  background-color: var(--primary);
}
/* ANTD BUTTON HOVER CLASS */
.ant-btn:hover{
  color: var(--bs-white) !important;
}
.flipped {
  transform: rotateY(180deg);
  transition: transform 0.5s ease;
}
/* custom-table.css */
.history-table thead.ant-table-thead tr th {
  background-color: var(--primary);
  color: var(--bs-white);
  font-weight: 700;
  padding: 10px 20px;
  font-size: 13px;
  text-align: start !important;
  /* Set your desired background color */
  /* Add any other styles you want for the table header */
}
.history-table th.ant-table-cell .ant-table-column-has-sorters {
  /* background-color: var(--bs-gray-100) !important; */
  text-align: center !important;
}
.history-table th.ant-table-cell {
  text-align: center;
  border-radius: 0px !important;
}
.history-table .ant-table-tbody > tr > td {
  padding: 10px 20px;
  text-align: start;
  font-weight: 600;
}

.history-table .ant-table-tbody > tr {
  height: 2px;
  line-height: 24px;
}
.ant-table-filter-dropdown .ant-table-filter-dropdown-btns button:nth-child(2) {
  background-color: #113150 !important;
}
.history-table .ant-table-tbody{
  background-color: #ffffff;
}
.table-rows .ant-table-tbody {
  background-color: #162636;
  color: #ffffff;
}

.table-rows .ant-table-tbody > tr:hover {
  color: #000000;
}
/* ANTD CALENDER */
.ant-picker-header{
  background-color: var(--primary);
}
.ant-picker {
  border-radius: 0px ;
}
.ant-picker:hover{
  border-color: var(--primary);
}
.bottom-calendar .ant-picker-dropdown {
  top: auto;
  bottom: 0;
}

/* ANTD STEPS DESINING CHANGE */

/* .ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after{
  background-color: var(--primary) !important;
}
.ant-steps-item-active .ant-steps-icon-dot{
  background-color: var(--primary) !important;
} */
/* ANTD FORM LABEL */
.ant-col-24.ant-form-item-label{
  padding: 0px !important;
}
.ant-form-item{
  margin-bottom: 3px !important;
}
.ant-input:hover,input:hover{
  border-color: var(--primary) ;
}
.ant-input-password:hover{
  border-color: var(--primary) !important;

}
/* ANTD PAGINATION DESIGN */
.ant-pagination-item-active{
  background-color: var(--primary) !important;
  border: none !important;
}
.ant-pagination-item-active a{
 color: white !important;
}
/* ANTD PAGINATION DESIGN END */

/* ANTD PROFILE IMAGE MODAL */
.image-Modal .ant-modal-content{
  padding:0;
  border-radius: 50%;
}
.image-Modal .ant-modal-content button {
  display: none !important;
}
/* ANTD PROFILE IMAGE MODAL END */

/* ANTD DRAWER TITLE */
.drawer .ant-drawer-wrapper-body .ant-drawer-header .ant-drawer-header-title .ant-drawer-title, .ant-drawer-close{
  color: white !important;
  font-weight: 700;
}
/* ANTD DRAWER TITLE end */

.ant-skeleton-button{
  width: 275px !important;
  height: 100px !important;
}

.container {
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.card {
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  padding: 2em 0;
  background: #fff;
  box-shadow: 0 0 6px 0 rgba(32, 32, 36, .12);
  transition: all 0.35s ease;
}
.card::before, .card::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--primary);
  height: 4px;
}
.card::before {
  width: 0;
  opacity: 0;
  transition: opacity 0 ease, width 0 ease;
  transition-delay: 0.5s;
}
.card::after {
  width: 100%;
  background: white;
  transition: width 0.5s ease;
}
.card .content {
  width: 18em;
  max-width: 80%;
}
.card .logo {
  margin: 0 0 1em;
  width: 10.625em;
  transition: all 0.35s ease;
}
.card h6 {
  color: #999;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;
  letter-spacing: 2px;
}
.card .hover_content {
  overflow: hidden;
  max-height: 0;
  transform: translateY(1em);
  transition: all 0.55s ease;
}
.card .hover_content p {
  margin: 1.5em 0 0;
  color: #6e6e70;
  line-height: 1.4em;
}
.card:hover {
  box-shadow: 0 10px 20px 0 rgba(32, 32, 36, .12);
}
.card:hover::before {
  width: 100%;
  opacity: 1;
  transition: opacity 0.5s ease, width 0.5s ease;
  transition-delay: 0;
}
.card:hover::after {
  width: 0;
  opacity: 0;
  transition: width 0 ease;
}
.card:hover .logo {
  margin-bottom: 0.5em;
}
.card:hover .hover_content {
  max-height: 10em;
  transform: none;
}
.bg-primary,.bg-primaryhover,.border-primary,.border-primaryhover{
  background-color: var(--primary);
}
.text-primary{
  color: white;
  background-color: var(--primary);
}